import React from 'react'
import Article, { VARIANT_TEXT_INTENDED } from '../modules/Article.js'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components2/Layout'
import SEO from '../components/SEO'

const pageConfig = {
  title: `Datenschutz - ${config.siteTitle}`,
  slug: `datenschutz`,
  path: `datenschutz`
}

const Page = ({ data }) => {
  const postNode = {
    title: pageConfig.title,
  }

  const block1 = {
    as: 'header',
    leading: 5,
    variant: VARIANT_TEXT_INTENDED,
    headline: `Datenschutz`,
    lead: `<p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.</p>`,
    copytext: `
      <p>Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.</p>
      <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.</p>
    `,
  }

  const block2 = {
    as: 'section',
    leading: 5,
    variant: VARIANT_TEXT_INTENDED,
    headlineSize: 3,
    headline: `Server-Log-Files`,
    copytext: `
      <p>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log Files, die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>
      <ul>
        <li>Browsertyp/Browserversion</li>
        <li>verwendetes Betriebssystem</li>
        <li>Referrer URL</li>
        <li>Hostname des zugreifenden Rechners</li>
        <li>Uhrzeit der Serveranfrage</li>
      </ul>
      <p>Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.</p>
    `,
  }

  const block3 = {
    as: 'section',
    leading: 5,
    variant: VARIANT_TEXT_INTENDED,
    headlineSize: 3,
    headline: `Widerspruch Werbe-Mails`,
    copytext: `
      <p>Der  Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.</p>
    `,
  }

  return (
    <Layout>
      <Helmet>
        <title>{pageConfig.title}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath={pageConfig.path} customTitle />

      <div>
        <article>
          <Article {...block1}></Article>
          <Article {...block2}></Article>
          <Article {...block3}></Article>
        </article>
      </div>
    </Layout>
  )
}

export default Page
